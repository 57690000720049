// ----------------------------------------------------------------------

export const _id = [...Array(40)].map(
  (_, index) => `e99f09a7-dd88-49d5-b1c8-1daf80c2d7b${index + 1}`
);

export const _fullAddress = [
  '19034 Verna Unions Apt. 164 - Honolulu, RI / 87535',
  '1147 Rohan Drive Suite 819 - Burlington, VT / 82021',
  '18605 Thompson Circle Apt. 086 - Idaho Falls, WV / 50337',
  '110 Lamar Station Apt. 730 - Hagerstown, OK / 49808',
  '36901 Elmer Spurs Apt. 762 - Miramar, DE / 92836',
  '2089 Runolfsson Harbors Suite 886 - Chapel Hill, TX / 32827',
  '279 Karolann Ports Apt. 774 - Prescott Valley, WV / 53905',
  '96607 Claire Square Suite 591 - St. Louis Park, HI / 40802',
  '9388 Auer Station Suite 573 - Honolulu, AK / 98024',
  '47665 Adaline Squares Suite 510 - Blacksburg, NE / 53515',
  '989 Vernice Flats Apt. 183 - Billings, NV / 04147',
  '91020 Wehner Locks Apt. 673 - Albany, WY / 68763',
  '585 Candelario Pass Suite 090 - Columbus, LA / 25376',
  '80988 Renner Crest Apt. 000 - Fargo, VA / 24266',
  '28307 Shayne Pike Suite 523 - North Las Vegas, AZ / 28550',
  '205 Farrell Highway Suite 333 - Rock Hill, OK / 63421',
  '253 Kara Motorway Suite 821 - Manchester, SD / 09331',
  '13663 Kiara Oval Suite 606 - Missoula, AR / 44478',
  '8110 Claire Port Apt. 703 - Anchorage, TN / 01753',
  '4642 Demetris Lane Suite 407 - Edmond, AZ / 60888',
  '74794 Asha Flat Suite 890 - Lancaster, OR / 13466',
  '8135 Keeling Pines Apt. 326 - Alexandria, MA / 89442',
  '441 Gibson Shores Suite 247 - Pasco, NM / 60678',
  '4373 Emelia Valley Suite 596 - Columbia, NM / 42586',
];

// ----------------------------------------------------------------------

export const _booleans = [
  true,
  true,
  true,
  false,
  false,
  true,
  false,
  false,
  false,
  false,
  true,
  true,
  true,
  false,
  false,
  false,
  true,
  false,
  false,
  false,
  true,
  false,
  false,
  true,
];

// ----------------------------------------------------------------------

export const _emails = [
  'nannie_abernathy70@yahoo.com',
  'ashlynn_ohara62@gmail.com',
  'milo.farrell@hotmail.com',
  'violet.ratke86@yahoo.com',
  'letha_lubowitz24@yahoo.com',
  'aditya_greenfelder31@gmail.com',
  'lenna_bergnaum27@hotmail.com',
  'luella.ryan33@gmail.com',
  'joana.simonis84@gmail.com',
  'marjolaine_white94@gmail.com',
  'vergie_block82@hotmail.com',
  'vito.hudson@hotmail.com',
  'tyrel_greenholt@gmail.com',
  'dwight.block85@yahoo.com',
  'mireya13@hotmail.com',
  'dasia_jenkins@hotmail.com',
  'benny89@yahoo.com',
  'dawn.goyette@gmail.com',
  'zella_hickle4@yahoo.com',
  'avery43@hotmail.com',
  'olen_legros@gmail.com',
  'jimmie.gerhold73@hotmail.com',
  'genevieve.powlowski@hotmail.com',
  'louie.kuphal39@gmail.com',
];

// ----------------------------------------------------------------------

export const _fullNames = [
  'Maria Gonzalez',
  'Juan Rodriguez',
  'Jose Gomez',
  'Chin Dien',
  'Jorge Lopez',
  'Luis Diaz',
  'Miguel Martinez',
  'Ana Perez',
  'Hector Garcia',
  'Ramon Sanchez',
  'Silvia Romero',
  'Rosa Sosa',
  'Roberto Torres',
  'Oscar Alvarez',
  'Daniel Ruiz',
  'Norma Ramirez',
  'Marta Flores',
  'Pedro Benitez',
  'Ricardo Acosta',
  'Graciela Medina',
  'Raul Herrera',
  'Pablo Gutierrez',
  'Eduardo Pereyra',
  'Marcelo Rojas',
];

export const _firstNames = [
  'Maria',
  'Juan',
  'Jose',
  'Carlos',
  'Jorge',
  'Luis',
  'Miguel',
  'Ana',
  'Hector',
  'Ramon',
  'Silvia',
  'Rosa',
  'Roberto',
  'Oscar',
  'Daniel',
  'Norma',
  'Marta',
  'Pedro',
  'Ricardo',
  'Graciela',
  'Raul',
  'Pablo',
  'Eduardo',
  'Marcelo',
];

export const _lastNames = [
  'Gonzalez',
  'Rodriguez',
  'Gomez',
  'Fernandez',
  'Lopez',
  'Diaz',
  'Martinez',
  'Perez',
  'Garcia',
  'Sanchez',
  'Romero',
  'Sosa',
  'Torres',
  'Alvarez',
  'Ruiz',
  'Ramirez',
  'Flores',
  'Benitez',
  'Acosta',
  'Medina',
  'Herrera',
  'Gutierrez',
  'Pereyra',
  'Rojas',
];

// ----------------------------------------------------------------------

export const _prices = [
  83.74, 97.14, 68.71, 85.21, 52.17, 25.18, 43.84, 60.98, 98.42, 53.37, 72.75, 56.61, 64.55, 77.32,
  60.62, 79.81, 93.68, 47.44, 76.24, 92.87, 72.91, 20.54, 94.25, 37.51,
];

export const _ratings = [
  4.2, 3.7, 4.5, 3.5, 4.5, 5.0, 3.6, 2.8, 4.9, 3.6, 2.5, 1.7, 3.9, 2.8, 4.1, 4.5, 2.2, 3.2, 0.6,
  1.3, 3.8, 3.8, 3.8, 2.0,
];

export const _ages = [
  30, 26, 59, 47, 29, 46, 18, 56, 39, 19, 45, 18, 46, 56, 38, 41, 44, 48, 32, 45, 42, 60, 33, 57,
];

export const _percents = [
  10.1, 13.6, 28.2, 42.1, 37.2, 18.5, 40.1, 94.8, 91.4, 53.0, 25.4, 62.9, 86.6, 62.4, 35.4, 17.6,
  52.0, 6.8, 95.3, 26.6, 69.9, 92.1, 46.2, 85.6,
];

export const _nativeS = [
  11, 10, 7, 10, 12, 5, 10, 1, 8, 8, 10, 11, 12, 8, 4, 11, 8, 9, 4, 9, 2, 6, 3, 7,
];

export const _nativeM = [
  497, 763, 684, 451, 433, 463, 951, 194, 425, 435, 807, 521, 538, 839, 394, 269, 453, 821, 364,
  849, 804, 776, 263, 239,
];

export const _nativeL = [
  9911, 1947, 9124, 6984, 8488, 2034, 3364, 8401, 8996, 5271, 8478, 1139, 8061, 3035, 6733, 3952,
  2405, 3127, 6843, 4672, 6995, 6053, 5192, 9686,
];

// ----------------------------------------------------------------------

export const _phoneNumbers = [
  '4374-4961',
  '4966-2836',
  '4757-9909',
  '4767-2903',
  '4588-5716',
  '4439-2578',
  '4924-4058',
  '4917-1454',
  '4840-9338',
  '4269-2446',
  '4373-6253',
  '4509-8866',
  '4465-1954',
  '4295-9408',
  '4492-6028',
  '4699-7588',
  '4268-4826',
  '4952-3828',
  '4255-5190',
  '4439-8033',
  '4940-8266',
  '4685-8057',
  '4801-5212',
  '4285-8928',
];

// ----------------------------------------------------------------------

export const _roles = [
  'Gerente de Recursos Humanos',
  'Analista de datos',
  'Consejero legal',
  'Diseñador UX/UI',
  'Gerente de proyecto',
  'Gerente de cuentas',
  'Enfermera registrada',
  'Analista de negocios',
  'Director creativo',
  'Planeador financiero',
  'Coordinador de eventos',
  'Director de marketing',
  'Desarrollador de software',
  'Investigador científico',
  'Estratega de contenidos',
  'Gerente de Operaciones',
  'Representante de ventas',
  'Analista de Cadena de Suministro',
  'Coordinador de Operaciones',
  'Asociado de Servicio al Cliente',
  'Especialista en Garantía de Calidad',
  'CEO',
  'CFO',
  'CTO',
];

// ----------------------------------------------------------------------

export const _postTitles = [
  'Diez consejos esenciales para una vida sana',
  'La guía definitiva para trucos de productividad',
  'Explorando las joyas ocultas de [Destino]',
  'Cómo dominar el arte de hablar en público',
  'El futuro de la inteligencia artificial: tendencias y conocimientos',
  'Deliciosas Recetas para una Dieta Vegana',
  "Una guía para principiantes sobre cómo invertir en acciones",
  'El impacto de las redes sociales en la sociedad',
  'Diez destinos imprescindibles para viajeros aventureros',
  'Los beneficios de la meditación de atención plena',
  'La importancia de la concientización sobre la salud mental',
  'Construyendo una marca personal sólida: consejos y estrategias',
  'Diez estrategias efectivas para el éxito del marketing digital',
  'Revelando los secretos de los emprendedores exitosos',
  'El auge del trabajo remoto y su impacto en la fuerza laboral',
  'El arte de la fotografía de paisaje: técnicas e inspiración',
  'Comprensión de la tecnología Blockchain y sus posibles aplicaciones',
  'Cómo crear contenido atractivo para las redes sociales',
  'El papel de la inteligencia artificial en la asistencia sanitaria',
  'Diez trucos de organización del hogar para un espacio libre de desorden',
  'Explorando la historia y la cultura de [ciudad/región]',
  'El poder del pensamiento positivo: transforma tu forma de pensar',
  'La influencia de la música en el estado de ánimo y las emociones',
  'Consejos para la fotografía de viajes: capturar recuerdos de todo el mundo',
];

// ----------------------------------------------------------------------

export const _productNames = [
  'Nike Air Force 1 NDESTRUKT',
  'Foundations Matte Flip Flop',
  'Nike Air Zoom Pegasus 37 A.I.R. Chaz Bear',
  'Arizona Soft Footbed Sandal',
  'Boston Soft Footbed Sandal',
  'Zoom Freak 2',
  'Gazelle Vintage low-top sneakers',
  'Jordan Delta',
  'Air Jordan XXXV PF',
  'Rod Laver low-top sneakers',
  'Kyrie 7 EP Sisterhood',
  'Pharrell Williams Human Race NMD sneakers',
  'Nike Blazer Low 77 Vintage',
  'ASMC Winter Boot Cold.Rdy',
  'ZX 8000 Lego sneakers',
  'Ultraboost 21 sneakers',
  '2750 Cotu Classic Sneaker',
  'ZX 9000 A-ZX Series sneakers',
  'Madrid Big Buckle Sandal',
  'Chuck 70 Hi Sneaker',
  'Relaxed Adjustable Strap Slingback Sandal',
  'Superturf Adventure X Atmos',
  'Chuck Taylor All Star Lift Sneaker',
  'Run Star Hike Platform Sneaker',
];

// ----------------------------------------------------------------------

export const _tourNames = [
  'Expedición de buscadores de aventuras',
  'Tour Patrimonio Histórico',
  'Exploración de las delicias culinarias',
  "Escapada a las maravillas de la naturaleza",
  'Viaje de Inmersión Cultural',
  'Expedición de safari por la vida silvestre',
  "Tour por la ciudad del explorador urbano",
  'Escapada al paraíso costero',
  'Experiencia de Cata de Vinos',
  'Tour de retiro espiritual',
  'Caminata de aventura al aire libre',
  'Expedición fotográfica',
  'Tour de descubrimiento de la música y las artes',
  'Retiro de Bienestar y Yoga',
  'Tour de descubrimiento de gemas ocultas',
  'Exploración Volcánica y Geotérmica',
  'Tour Gastronómico Foodie',
  'Aventura de senderismo y acampada',
  'Tour de Arquitectura y Diseño',
  'Crucero por la costa y de isla en isla',
  'Experiencia de viaje panorámico en tren',
  'Expedición a Monumentos Históricos',
  'Surf y Aventura en la Playa',
  'Tour de Vida Nocturna y Entretenimiento',
];

// ----------------------------------------------------------------------

export const _jobTitles = [
  'Ingeniero de software',
  'Gerente de marketing',
  'Analista financiero',
  'Diseñador grafico',
  'Representante de ventas',
  'Gerente de proyecto',
  'Científico de datos',
  'Coordinador de Recursos Humanos',
  'Contador',
  'Representante de Servicio al Cliente',
  'Enfermero',
  'Gerente de producto',
  'Gerente de Operaciones',
  'Especialista en Social Media',
  'Ejecutivo de Negocios para el Desarrollo',
  'Escritor de contenido',
  'Desarrollador web',
  'Ingeniero eléctrico',
  'Investigador científico',
  'Asistente legal',
  'Cocinero',
  'Planeador financiero',
  'Arquitecto',
  'Organizador de eventos',
];

// ----------------------------------------------------------------------

export const _companyNames = [
  'Lueilwitz and Sons',
  'Gleichner, Mueller and Tromp',
  'Nikolaus - Leuschke',
  'Hegmann, Kreiger and Bayer',
  'Grimes Inc',
  'Durgan - Murazik',
  'Altenwerth, Medhurst and Roberts',
  'Raynor Group',
  'Mraz, Donnelly and Collins',
  'Padberg - Bailey',
  'Heidenreich, Stokes and Parker',
  'Pagac and Sons',
  'Rempel, Hand and Herzog',
  'Dare - Treutel',
  'Kihn, Marquardt and Crist',
  'Nolan - Kunde',
  'Wuckert Inc',
  'Dibbert Inc',
  'Goyette and Sons',
  'Feest Group',
  'Bosco and Sons',
  'Bartell - Kovacek',
  'Schimmel - Raynor',
  'Tremblay LLC',
];

// ----------------------------------------------------------------------

export const _tags = [
  'Tecnología',
  'Marketing',
  'Diseño',
  'Fotografía',
  'Arte',
  'Moda',
  'Alimento',
  'Viajar',
  'Aptitud física',
  'Naturaleza',
  'Negocio',
  'Música',
  'Salud',
  'Libros',
  'Deportes',
  'Película',
  'Educación',
  'Motivación',
  'Juego de azar',
  'Mascotas',
  'Cocinando',
  'Finanzas',
  'Cuidados personales',
  'Escritor',
];

// ----------------------------------------------------------------------

export const _taskNames = [
  'Propuesta completa de proyecto',
  'Realizar investigaciones de mercado',
  'Diseñar maquetas de interfaz de usuario',
  'Desarrollar API de backend',
  'Implementar sistema de autenticación',
  'Escribir casos de prueba',
  'Realizar la optimización de la base de datos',
  'Crear un plan de marketing de contenidos',
  'Actualizar copia del sitio web',
  'Realizar pruebas A/B',
  'Crear gráficos para redes sociales',
  'Optimizar el rendimiento del sitio web',
  'Revisar sitios web de la competencia',
  'Implementar la integración de la pasarela de pago',
  'Realizar pruebas de aceptación del usuario',
  'Preparar informe de ventas mensual',
  'Mejorar la estrategia de SEO',
  'Realizar una encuesta de satisfacción del cliente',
  'Diseño de plantilla de boletín informativo por correo electrónico',
  'Supervisar los registros del servidor en busca de errores',
  'Crear materiales de formación',
  'Planificar y ejecutar campañas de marketing',
  'Desarrollar aplicaciones móviles',
  'Coordinar Reuniones de Proyecto',
];

// ----------------------------------------------------------------------

export const _sentences = [
  'El sol se puso lentamente en el horizonte, pintando el cielo en vibrantes tonos de naranja y rosa.',
  'Ella abrió el regalo con entusiasmo, sus ojos brillaban de emoción.',
  'El viejo roble se alzaba alto y majestuoso, sus ramas se mecían suavemente con la brisa.',
  'El aroma del café recién hecho llenó el aire, despertando mis sentidos.',
  'Los niños reían de alegría mientras corrían entre los aspersores en un caluroso día de verano.',
  'Elaboró ​​cuidadosamente una hermosa escultura de arcilla, y sus manos dieron forma hábilmente a los intrincados detalles.',
  "El concierto fue una experiencia fascinante, con la música llenando el lugar y la multitud aplaudiendo de alegría.",
  'Las olas rompieron contra la orilla, creando una relajante sinfonía de sonido.',
  'El aroma de las flores en flor flotaba por el jardín, creando un paraíso fragante.',
  'Miró hacia el cielo nocturno, maravillándose de las estrellas titilantes que salpicaban la oscuridad.',
  'El profesor pronunció una conferencia cautivadora, involucrando a los estudiantes con ideas que invitaban a la reflexión.',
  'El excursionista caminó a través del denso bosque, guiado por el suave resplandor de la luz del sol que se filtraba entre los árboles.',
  'La delicada mariposa revoloteaba con gracia de flor en flor, sorbiendo el néctar con su esbelta trompa.',
  'El aroma de las galletas recién horneadas llenó la cocina, tentando a todos con su aroma irresistible.',
  'La majestuosa cascada caía sobre las rocas, creando una impresionante muestra del poder de la naturaleza.',
  'El actor realizó una actuación poderosa, que hizo llorar al público con su emotiva interpretación',
  'El libro me transportó a un mundo mágico, donde la imaginación no conocía límites.',
  'El olor a lluvia llenó el aire mientras nubes oscuras se acumulaban en lo alto, prometiendo un aguacero refrescante.',
  'El chef preparó hábilmente el plato, convirtiendo ingredientes simples en una obra de arte culinaria.',
  'El bebé recién nacido dejó escapar un llanto minúsculo, anunciando su llegada al mundo.',
  'El atleta cruzó corriendo la línea de meta, con los brazos levantados en señal de victoria mientras la multitud estalló en aplausos.',
  'Las antiguas ruinas son un testimonio de una civilización desaparecida hace mucho tiempo, y su grandeza aún es impresionante',
  'El artista sumergió el pincel en pintura vibrante, dando vida al lienzo con trazos atrevidos y colores vivos.',
  'Las risas de los niños resonaron en el patio de recreo, llenando el ambiente de pura alegría.',
];

// ----------------------------------------------------------------------

export const _descriptions = [
  `Occaecati est et illo quibusdam accusamus qui. Incidunt aut et molestiae ut facere aut. Est quidem iusto praesentium excepturi harum nihil tenetur facilis. Ut omnis voluptates nihil accusantium doloribus eaque debitis.`,
  `Atque eaque ducimus minima distinctio velit. Laborum et veniam officiis. Delectus ex saepe hic id laboriosam officia. Odit nostrum qui illum saepe debitis ullam. Laudantium beatae modi fugit ut. Dolores consequatur beatae nihil voluptates rem maiores.`,
  `Rerum eius velit dolores. Explicabo ad nemo quibusdam. Voluptatem eum suscipit et ipsum et consequatur aperiam quia. Rerum nulla sequi recusandae illum velit quia quas. Et error laborum maiores cupiditate occaecati.`,
  `Et non omnis qui. Qui sunt deserunt dolorem aut velit cumque adipisci aut enim. Nihil quis quisquam nesciunt dicta nobis ab aperiam dolorem repellat. Voluptates non blanditiis. Error et tenetur iste soluta cupiditate ratione perspiciatis et. Quibusdam aliquid nam sunt et quisquam non esse.`,
  `Nihil ea sunt facilis praesentium atque. Ab animi alias sequi molestias aut velit ea. Sed possimus eos. Et est aliquid est voluptatem.`,
  `Non rerum modi. Accusamus voluptatem odit nihil in. Quidem et iusto numquam veniam culpa aperiam odio aut enim. Quae vel dolores. Pariatur est culpa veritatis aut dolorem.`,
  `Est enim et sit non impedit aperiam cumque animi. Aut eius impedit saepe blanditiis. Totam molestias magnam minima fugiat.`,
  `Unde a inventore et. Sed esse ut. Atque ducimus quibusdam fuga quas id qui fuga.`,
  `Eaque natus adipisci soluta nostrum dolorem. Nesciunt ipsum molestias ut aliquid natus ut omnis qui fugiat. Dolor et rem. Ut neque voluptatem blanditiis quasi ullam deleniti.`,
  `Nam et error exercitationem qui voluptate optio. Officia omnis qui accusantium ipsam qui. Quia sequi nulla perspiciatis optio vero omnis maxime omnis ipsum. Perspiciatis consequuntur asperiores veniam dolores.`,
  `Perspiciatis nulla ut ut ut voluptates totam consectetur eligendi qui. Optio ut cum. Dolorum sapiente qui laborum. Impedit temporibus totam delectus nihil. Voluptatem corrupti rem.`,
  `Distinctio omnis similique omnis eos. Repellat cumque rerum nisi. Reiciendis soluta non ut veniam temporibus. Accusantium et dolorem voluptas harum. Nemo eius voluptate dicta et hic nemo. Dolorem assumenda et beatae molestias sit quo mollitia quis consequatur.`,
  `Sed ut mollitia tempore ipsam et illum doloribus ut. Occaecati ratione veritatis explicabo. Omnis nam omnis sunt placeat tempore accusantium placeat distinctio velit.`,
  `Eum illo dicta et perspiciatis ut blanditiis eos sequi. Ea veritatis aut et voluptas aut. Laborum eos quia tempore a culpa.`,
  `Aut quos quae dolores repudiandae similique perferendis perferendis earum laudantium. Facere placeat natus nobis. Eius vitae ullam dolorem.`,
  `Vero dolorem et voluptatem fugit tempore a quam iure. Fuga consequatur corrupti sunt asperiores vitae. Libero totam repellendus animi debitis illum et sunt officia.`,
  `Cupiditate illum officiis id molestiae. Numquam non molestiae aliquid et natus sed hic. Alias quia explicabo sed corrupti sint. Natus in et odio qui unde facilis quia. Est sit eius laboriosam aliquid non aperiam quia quo corporis.`,
  `Et a ab. Optio aspernatur minus tempora amet vitae consectetur inventore cumque. Sed et omnis. Aspernatur a magnam.`,
  `Ipsum omnis et. Quia ea et autem tempore consequuntur veniam dolorem officiis. Ipsa dicta et ut quidem quia doloremque. Sequi vitae doloremque temporibus. Deserunt incidunt id aperiam itaque natus. Earum sit eaque quas incidunt nihil.`,
  `Quae consequatur reiciendis. Consequatur non optio. Eaque id placeat. Commodi quo officia aut repudiandae reiciendis tempore voluptatem et. Ut accusamus qui itaque maxime aliquam. Fugit ut animi molestiae porro maiores.`,
  `Modi hic asperiores ab cumque quam est aut. Voluptas atque quos molestias. Ut excepturi distinctio ipsam aspernatur sit.`,
  `Sunt totam facilis. Quam commodi voluptatem veniam. Tempora deleniti itaque fugit nihil voluptas.`,
  `Ipsam aliquam velit nobis repellendus officiis aut deserunt id et. Nihil sunt aut dolores aut. Dolores est ipsa quia et laborum quidem laborum accusamus id. Facilis odit quod hic laudantium saepe omnis nisi in sint. Sed cupiditate possimus id.`,
  `Magnam non eveniet optio optio ut aliquid atque. Velit libero aspernatur quis laborum consequatur laudantium. Tempora facere optio fugit accusantium ut. Omnis aspernatur reprehenderit autem esse ut ut enim voluptatibus.`,
];
