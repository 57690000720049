// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_DESKTOP: (/sgEmbed/.test(window.location.search)) ? 16 : 80,
  H_DESKTOP_OFFSET: (/sgEmbed/.test(window.location.search)) ? 16 : 80 - 16,
  // H_DESKTOP: 10,
  // H_DESKTOP_OFFSET: 10 - 16,
};

export const NAV = {
  W_VERTICAL: 280,
  W_MINI: 88,
};
