import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
// utils
import axios, { endpoints } from 'src/utils/axios';
//
import { AuthContext } from './auth-context';
// import { isValidToken, setSession } from './utils';
import { setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [searchParams] = useSearchParams();
  const initialize = useCallback(async () => {
    try {      
      let u = searchParams.get('u')
      const origin = window.location.origin.toString()
      const allowedPublicSites = {
        'https://minimals.socialgateway.net': true,
        'https://centelar.com': true,
        'http://localhost:3030': true,
      }
      if (!u) {
        if (allowedPublicSites[origin]) {
          if (origin === 'https://centelar.com') {
            u = '{"userName":"Demo User","sessionId":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI4ODY0YzcxNy01ODdkLTQ3MmEtOTI5YS04ZTVmMjk4MDI0ZGEtMCIsImlhdCI6MTY5MjI5MjQ2OCwiZXhwIjoxNjkyNTUxNjY4fQ.ZayAbbE39qqxYHswXrSW8aNLYsTrTM8Y_pqCEBVee3U"}';
          } else {
            u = '{"userName":"support","sessionId":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI4ODY0YzcxNy01ODdkLTQ3MmEtOTI5YS04ZTVmMjk4MDI0ZGEtMCIsImlhdCI6MTY5MjI5MjQ2OCwiZXhwIjoxNjkyNTUxNjY4fQ.ZayAbbE39qqxYHswXrSW8aNLYsTrTM8Y_pqCEBVee3U"}';
          }          
        } else {
          const response = await axios.get(endpoints.auth.me);
          const { user } = response.data;
          if (user) {
            dispatch({
              type: 'INITIAL',
              payload: {
                user,
              },
            });
            return
          }
          if (allowedPublicSites[origin]) {
            console.log('no hay user - estamos en Centelar?')
            dispatch({
              type: 'INITIAL',
              payload: {
                user: null,
              },
            });
            return
          }
        }
      }
      const user = JSON.parse(u)
      const accessToken = user.sessionId
      // const userName = u.userName

      if (!accessToken) {
        console.log('no hay access token - estamos en Centelar?')
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
        return
      }

      setSession(accessToken);
      dispatch({
        type: 'INITIAL',
        payload: {
          // user,
          user: {
            id: user.userName, // '8864c717-587d-472a-929a-8e5f298024da-0',
            displayName: user.alias || user.firstName || user.name || user.userName,
            // email: 'demo@minimals.cc',
            // password: 'demo1234',
            // phoneNumber: '+40 777666555',
            // country: 'United States',
            // address: '90210 Broadway Blvd',
            // state: 'California',
            // city: 'San Francisco',
            // zipCode: '94116',
            // about: 'Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.',
            role: 'admin',
            isPublic: true
          }
        },
      });
      // let accessToken = useSearchParams['session'];//sessionStorage.getItem(STORAGE_KEY);

      // if (!accessToken) {
      //   accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI4ODY0YzcxNy01ODdkLTQ3MmEtOTI5YS04ZTVmMjk4MDI0ZGEtMCIsImlhdCI6MTY5MjI5MjQ2OCwiZXhwIjoxNjkyNTUxNjY4fQ.ZayAbbE39qqxYHswXrSW8aNLYsTrTM8Y_pqCEBVee3U'
      // }

      // if (accessToken && isValidToken(accessToken)) {
      //   setSession(accessToken);

      //   // const response = await axios.get(endpoints.auth.me);
      //   // const { user } = response.data;

      //   dispatch({
      //     type: 'INITIAL',
      //     payload: {
      //       // user,
      //       user: {
      //         id: '8864c717-587d-472a-929a-8e5f298024da-0',
      //         displayName: 'Jaydon Frankie',
      //         email: 'demo@minimals.cc',
      //         password: 'demo1234',
      //         phoneNumber: '+40 777666555',
      //         country: 'United States',
      //         address: '90210 Broadway Blvd',
      //         state: 'California',
      //         city: 'San Francisco',
      //         zipCode: '94116',
      //         about: 'Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.',
      //         role: 'admin',
      //         isPublic: true
      //       }
      //     },
      //   });
      // } else {
      //   dispatch({
      //     type: 'INITIAL',
      //     payload: {
      //       user: null,
      //     },
      //   });
      // }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, [searchParams]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {

    const data = {
      email,
      password,
    };

    const response = await axios.post(endpoints.auth.login, data);

    const { accessToken, user } = response.data;

    setSession(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const data = {
      email,
      password,
      firstName,
      lastName,
    };

    const response = await axios.post(endpoints.auth.register, data);

    const { accessToken, user } = response.data;

    sessionStorage.setItem(STORAGE_KEY, accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'sg',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
    }),
    [login, logout, register, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
